import React from "react";
import * as styles from "./styles.module.scss";
import Card from "../../../atoms/simpleCard";
import icon1 from '../../../../images/user-icon.png';
import icon2 from '../../../../images/tech-icon.png';
import icon3 from '../../../../images/security-icon.png';


export default function index() {
  return (
    <div className={styles.unique}>
      <div className={styles.content}>
        <div className={styles.desc}>
          <h2>For the "Common Good" backed by Impact Investors</h2>
          <p className={styles.subtitle}>
          We are not a traditional software and communications partner, we strongly focus on values beyond traditional business targets.
          </p>
        </div>
        <div className={styles.cards}>
          <Card
            title="Diversity a Superpower"
            desc="We actively work on 4 continents and use the diversity in experience, background and skills to create a unique learning, innovation and digital workspace."
            icon={icon1}
            color="#EC7600"
          />
          <Card
            title="From 1% to the 99%"
            desc="We take inspirations from how only the biggest companies can excel in their communications and bring more opportunities to shine professionally."
            icon={icon2}
            color="#2E6055"
            />
          <Card
            title="Partner-driven"
            desc="With a Universal Communication Tool we need to think wide and ambitious. We empower organizations that have high goals for how the foster learning, growth and opportunity for their students and members."
            icon={icon3}
            color="#0693DA"
          />
        </div>
      </div>
    </div>
  );
}