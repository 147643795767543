export const eduList  = [
    'Full time and part time jobs' ,
    'Internships',
    'Guest speakers',
    'Half or full day events',
    'E-learning courses',
    'Real life course exercises',
    'Beta user testing experience',
    'Applied research',
    'Integration of MW system in everyday work',
    'Capacity building'
]