import React from "react";
import * as styles from "./styles.module.scss";

export default function index() {
  return (
    <div className={styles.moduleCompanies}>
      <h2>Helping to create engaging, productive and profitable companies</h2>
      <p>
        <span>MASTER WiZR is an international company</span> delivering a premium meeting and
        presentation system aiming to revolutionize how students, freelances,
        consultants, companies and organisations present and connect. Check our
        web page and get to know more through our hiring process. It is an
        opportunity to apply yourself intentionally and seeking to meet
        excellence in design and technical implementation.
      </p>
    </div>
  );
}
