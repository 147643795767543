import React from "react";
import * as styles from "./styles.module.scss";
import {Image} from '../../../../hooks/gatsbyImg';
import StyledButton from "../../../atoms/StyledButton";
import { IoIosArrowForward } from "react-icons/io";

export default function index() {
  return (
    <div className={styles.findJob}>
      <div className={styles.desc}>
        <div>
          <h4> Work Partner </h4>
          <h3>Find the Job that fits your Life</h3>
          <p>
          <span>We are looking for highly motivated individuals. </span> We recruit both experienced and also students giving special talents and unparalleled opportunity to apply and grow new skills. You are both used to work independently and in teams. Driven, performance focused and look for working on software that is meant to make a difference in people's lives and careers - Democratizing Communications. Strong track record and resumes together with English and collaboration skills.
          </p>
            <StyledButton title="See Open Positions"
            icon={<IoIosArrowForward />}
            extraClass="whiteLink"
            link="/hiring"
            />
          </div>
      </div>
      <div>
          <Image filename='HR-image.webp' />
      </div>
    </div>
  );
}