import image1 from '../images/universities.webp';
import image2 from '../images/NGO.webp';
import image3 from '../images/interest.webp';

export const institutionsData = [
    {
        image:image1,
        title:'Universities/colleges',
        desc:'Collaborating with workshops, materials, job opportunities and knowledge exchange with:',
        list:[
            'Universities',
            'Colleges',
            'Course providers',
            'Specialized schools'
        ]
    },
    {
        image:image2,
        title:'NGOs',
        desc:'Supporting in strategic missions, projects and activities to support work opportunity, knowledge exchange and personal growth opportunities',
        list:[
            'Green tech',
            'People rights',
            'Empowerment',
            'Charity'
        ]
    },
    {
        image:image3,
        title:'Special interest organizations',
        desc:'Doing joint events, supporting on campaigns and value-added member benefits to:',
        list:[
            'Business associations',
            'Specialty clusters/community',
            'R&D centers',
            'Chamber of Commerce'
        ]
    }
]