import React from 'react';
import { institutionsData } from '../../../../constants/institutionsData';
import InstitutionCard from '../../../atoms/institutionCard';
import * as styles from './styles.module.scss';

function Institutions(){
    return(
        <div className={styles.institutionsPart}>
           <h4>Institutions</h4>
           <h1>Empowering special missions to excel in ways to connect and communicate</h1>
           <div className={styles.cards}>
               {institutionsData.map((d) =>{
                   return(
                       <InstitutionCard {...d} />
                   )
               })}
           </div>
        </div>
    )
};
export default Institutions ;