import React from 'react';
import { eduList } from '../../../../constants/educationList';
import * as styles from './styles.module.scss';

function EducationPartner(){
    return(
        <div className={styles.eduPartner}>
            <div className={styles.imagePart}></div>
            <div className={styles.content}>
            <div className={styles.eduContent}>
                <h4>Education Partner</h4>
                <h1>With educational institutions we can collaborate and a broad range of areas:</h1>
                <ol>
                    {eduList.map((item,d) =>{
                        return(
                            <li key={d}>
                                {item}
                                </li>
                        )
                    })}
                </ol>
            </div>
            </div>
        </div>
    )
};
export default EducationPartner ;