export const communicationsData = [
    {
        title:'MW Products',
        list: [
            'Video Conferencing',
            '3D Spaces w/customizations',
            'Premium White label Video Conf',
            'MW Presentation Portal'
        ],
    },
    {
        title:'MW Services',
        list:[
            'Branding-Brand-Boost',
            'Presentation harmonization',
            'Web and virtual presence',
            'Strategic communications'
        ]
    }
]