import React from 'react'
import * as styles from './styles.module.scss';

export default function index({icon, title, desc,color}) {
    return (
        <div className={styles.card}>
            <div className={styles.icon} style={{ backgroundColor: `${color}` }}>
                <img src={icon}  width={35} height={33} />
            </div>
            <h2>{title}</h2>
            <p>
                {desc}
            </p>
        </div>
    )
}