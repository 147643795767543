import React from "react";
import HeroBanner from "../../atoms/HeroBanner";
import * as styles from './banner.module.scss';
import StyledButton from "../../atoms/StyledButton";
import {IoIosArrowForward} from 'react-icons/io';

function index() {
  return (
    <div >
      <HeroBanner filename="headerimgHR.webp" extraClass="bannerModules">
        <div className={styles.hrModules}>
          <p data-aos="zoom-in" data-aos-delay="100">Solution Partner</p>
          <h2 data-aos="zoom-in" data-aos-delay="200">Collaborations</h2>
          <div data-aos="flip-down" data-aos-delay="100">
          <StyledButton title="Write Us"
          extraClass="whiteBtn"
          link="/contact"
          icon={<IoIosArrowForward />}
           />
          </div>
        </div>
      </HeroBanner>
    </div>
  );
}

export default index;