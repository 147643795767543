import React from 'react';
import { communicationsData } from '../../../../constants/communicationsData';
import * as styles from './styles.module.scss';

function Communications(){
    return(
        <div className={styles.communicationsPartner}>
            <h4>Communications Partner</h4>
            <div className={styles.mainContent}>
                <h1>In addition to knowledge exchange and work opportunities, we are a Communications Partner.</h1>
                {communicationsData.map((item) =>{
                    return(
                        <div>
                        <h1>{item.title}</h1>
                        <ul>
                        {item.list.map((listitem) =>{
                            return(
                                <li>
                                    {listitem}
                                </li>
                            )
                        })}
                        </ul>
                        </div>
                        
                    )
                })}
            </div>
        </div>
    )
};
export default Communications ;