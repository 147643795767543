import React, {useEffect} from 'react';
import Footer from "../components/atoms/footer";
import Header from '../components/atoms/header'
import Banner from '../components/ogranisms/hrBanner';
import Companies from '../components/molecules/hr/companies';
import FindJob from '../components/molecules/hr/findJob';
import Unique from '../components/molecules/hr/unique';
import RelaxBanner from '../components/molecules/hr/bottomBanner';
import FreeTrial from "../components/atoms/freeTrial";
import Head from "../components/ogranisms/head";
import AOS from "aos";
import "aos/dist/aos.css";
import EducationPartner from '../components/molecules/hr/educationpartner';
import Communications from '../components/molecules/hr/communications';
import Institutions from '../components/molecules/hr/institutions';
function Index() {
  useEffect(() => {
    AOS.init({
      disable: 'mobile',
      duration : 1500
    });
  }, []);
    return (
      <>
       <Head
        title="Human Resources"
        description="MASTER WiZR is an international company delivering a premium meeting and presentation system aiming to revolutionize how students, freelances, consultants, companies and organisations present and connect. Check our web page and get to know more through our hiring process. It is an opportunity to apply yourself intentionally and seeking to meet excellence in design and technical implementation."
        ogTitle="Human Resources"
      />
        <main>
          <Header />
            <Banner />
            <Companies />
            <FindJob />
            <Unique />
            <EducationPartner />
            <Communications />
            <Institutions />
            <RelaxBanner />
            <FreeTrial />
          <Footer/>
      </main>
      </>
    );
}

export default Index;