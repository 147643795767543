import React from 'react';
import * as styles from './styles.module.scss';

function InstitutionCard({image,title,desc,list}){
    return(
        <div className={styles.institutionCard}>
            <div className={styles.imagePart} style={{ backgroundImage: `url(${image})` }}></div>
            <h2>{title}</h2>
            <p>{desc}</p>
            <ul>
                {list.map((item) =>{
                    return(
                        <li>{item} </li>
                    )
                })}
            </ul>
        </div>
    )
};
export default InstitutionCard;