import React from 'react'
import {Image} from '../../../../hooks/gatsbyImg'
import * as styles from './styles.module.scss';

export default function index() {
    return (
        <>
        <div className={styles.banner}>
        </div>
        <h1 className={styles.growText}>Join us and obtain the opportunity to grow with MASTER WiZR</h1>
        </>
    )
}
